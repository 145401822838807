@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "KoPub Batang";
  src: local("KoPub Batang"),
    url(./fonts/KoPubBatang/KoPubBatang-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  @apply pt-14 font-kopub-batang; /* Applied KoPub Batang font-family */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply flex flex-col items-center;
}

#root {
  @apply w-full max-w-screen-2xl;
}

@layer utilities {
  .font-kopub-batang {
    font-family: "KoPub Batang";
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  @apply p-4 outline-none border border-gray-300 my-1;
}

.gallery-container {
  display: grid !important;
  grid-gap: 1rem !important;
  padding: 1rem !important;
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

@media (min-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 1024px) {
  .gallery-container {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

.gallery-masonry {
  column-count: 3;
  column-gap: 1rem;
}

.gallery-masonry img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 1rem;
  break-inside: avoid;
}

@media (max-width: 1023px) {
  .gallery-masonry {
    column-count: 2;
  }
}

@media (max-width: 767px) {
  .gallery-masonry {
    column-count: 1;
  }
}

.navBar {
  background-color: #d6cec2;
  color: #333333;
}
m-bride {
  background-color: #f5f3f0;
}

.heart-icon {
  position: absolute;
}

.text-3 {
  color: #333333;
}
.text-4 {
  color: #444444;
}
.text-5 {
  color: #555555;
}
.text-beigeC {
  color: #beb19d;
}
.text-7 {
  color: #777777;
}
.text-8 {
  color: #888888;
}
.bg-beigeC {
  background-color: #beb19d;
}

.home-message {
  font-weight: 300;
  font-size: 24px;
  line-height: 54px;
  color: #444444;
}
@media (max-width: 1023px) {
  .home-message {
    font-size: 20px;
    line-height: 45px;
  }
}
@media (max-width: 768px) {
  .home-message {
    font-size: 15px;
    line-height: 35px;
  }
}

.main-button {
  background-color: #beb19d !important;
  color: white;
}
