@keyframes wiggle {
  0%,
  100% {
    transform: rotate(-2deg);
  }
  50% {
    transform: rotate(2deg);
  }
}

.animate-wiggle {
  animation: wiggle 1.5s ease-in-out infinite;
  display: flex;
  justify-content: center;
  padding: 0 !important;
  width: 450px;
  height: 400px;
}
@media (max-width: 1023px) {
  .animate-wiggle {
    width: 450px;
    height: 400px;
  }
}
@media (max-width: 768px) {
  .animate-wiggle {
    width: 350px;
    height: 350px;
  }
}
